import "./style.scss";

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Link } from "@mui/material";
import { logEvent } from "@src/appV2/lib/analytics";
import { SupportArticles, SupportContext } from "@src/appV2/support/constants";
import { useSupportLinks } from "@src/appV2/support/hooks/useSupportLinks";
import { USER_EVENTS } from "@src/constants";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";

import { LogoutButton } from "./LogoutButton";
import { GlobalRouterPath } from "../routing/constant/globalRoute";
import { useSession } from "../store/helperHooks";
import { logout, setIsSignup } from "../store/session";

/**
 * @deprecated
 */
export const NoUser: FC<RouteComponentProps> = ({ history }) => {
  const { isAuthorized, userId } = useSession();
  const dispatch = useDispatch();
  const { howToContactSupportLink } = useSupportLinks();

  if (!isAuthorized) {
    return <Redirect to="/" />;
  }

  if (userId) {
    return <Redirect to={GlobalRouterPath.HOME} />;
  }

  const initSignup = () => {
    setIsSignup(dispatch);
    history.push(GlobalRouterPath.HOME);
  };

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonTitle>No Details Found</IonTitle>
          <IonButtons slot="end">
            <LogoutButton color="light" mode="ios" logout={logout} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent class="not-found-content ion-padding ion-text-center">
        <IonRow>
          <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
            <div className="text-container text-with-points">
              <h4>We couldn't find user details!</h4>
              <ul>
                <li>
                  If you have initiated Signup Process, please{" "}
                  <IonButton type="button" onClick={initSignup}>
                    Go to Signup Page
                  </IonButton>
                </li>
                <li>If you are an existing user, try log out and login again</li>
                <li>If issue still persists, reach out to Clipboard Support Team</li>
              </ul>
            </div>
          </IonCol>
        </IonRow>
        {howToContactSupportLink && (
          <Link
            href={howToContactSupportLink}
            target="_blank"
            color="#fff"
            sx={{
              ":hover": {
                color: "#fff",
              },
            }}
            onClick={() => {
              logEvent(USER_EVENTS.OPENED_SUPPORT_ARTICLE, {
                articleLink: howToContactSupportLink,
                articleName: SupportArticles.HOW_TO_CONTACT_SUPPORT,
                supportContext: SupportContext.NO_USER_SCREEN,
              });
            }}
          >
            <b>Contact Our 24/7 Support Team</b>
          </Link>
        )}
      </IonContent>
    </IonPage>
  );
};
