import { useLocation } from "react-router-dom";

type QueryParams = Record<string, unknown>;

export function useSearchParams(): QueryParams {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const queryParams: QueryParams = {};

  searchParams.forEach((value, key) => {
    if (queryParams[key]) {
      if (Array.isArray(queryParams[key])) {
        (queryParams[key] as string[]).push(value);
      } else {
        queryParams[key] = [queryParams[key] as string, value];
      }
    } else {
      queryParams[key] = value;
    }
  });

  return queryParams;
}
