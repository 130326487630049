import { isDefined } from "@clipboard-health/util-ts";
import { useCallback, useEffect, useState } from "react";

interface TimerOptions {
  durationInSeconds?: number;
}

export function useCountdownTimer(options: TimerOptions = {}) {
  const { durationInSeconds = 60 } = options;
  const [startTimestamp, setStartTimestamp] = useState<number | undefined>(undefined);
  const [secondsLeft, setSecondsLeft] = useState<number>(durationInSeconds);

  useEffect(() => {
    if (!isDefined(startTimestamp)) {
      return undefined;
    }

    const timerInterval = setInterval(() => {
      const elapsedSeconds = Math.floor((Date.now() - startTimestamp) / 1000);
      const secondsRemaining = durationInSeconds - elapsedSeconds;
      if (secondsRemaining <= 0) {
        setStartTimestamp(undefined);
        setSecondsLeft(0);
      } else {
        setSecondsLeft(secondsRemaining);
      }
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [startTimestamp, durationInSeconds]);

  const startTimer = useCallback(() => {
    setStartTimestamp(Date.now());
    setSecondsLeft(durationInSeconds);
  }, [durationInSeconds]);

  return {
    countdownIsLoading: isDefined(startTimestamp),
    startTimer,
    secondsLeft,
  };
}
