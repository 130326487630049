import debounce from "lodash/debounce";
import { useEffect, useState } from "react";

export interface UseResizeObserverOptions {
  debounceMs?: number;
}

export function useResizeObserver(
  element: HTMLElement | undefined,
  options: UseResizeObserverOptions = {}
) {
  const { debounceMs = 300 } = options;

  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (!element) {
      return undefined;
    }

    const handleResize = debounce((entries: ResizeObserverEntry[]) => {
      if (!entries || entries.length === 0) {
        return;
      }

      const entry = entries[0];
      setSize({
        width: entry.contentRect.width,
        height: entry.contentRect.height,
      });
    }, debounceMs);

    const observer = new ResizeObserver(handleResize);
    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [debounceMs, element]);

  return size;
}
