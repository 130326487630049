interface SortByDefinedOrderOptions {
  items: string[];
  definedOrder: string[];
}

export function sortByDefinedOrder(options: SortByDefinedOrderOptions): string[] {
  const { items, definedOrder } = options;
  return [...items].sort((...itemsToSort) => {
    const [firstItemOrder, secondItemOrder] = itemsToSort.map((item) => {
      const itemIndex = definedOrder.indexOf(item);
      return itemIndex === -1 ? definedOrder.length : itemIndex;
    });

    return firstItemOrder - secondItemOrder;
  });
}
