import { isPlatform } from "@ionic/core";

export function isCapacitorPlatform(): boolean {
  return isPlatform("capacitor");
}

export function isAndroidPlatform(): boolean {
  return isPlatform("android");
}

export function isIosPlatform(): boolean {
  return isPlatform("ios");
}
