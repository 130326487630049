import { App, type AppInfo } from "@capacitor/app";
import { Device, type DeviceInfo } from "@capacitor/device";
import { Deploy } from "cordova-plugin-ionic";

import { APP_V2_APP_EVENTS, logError } from "../../analytics";
import { isCapacitorPlatform } from "./platformUtils";

export interface CbhAppInfo extends AppInfo {
  otaBuildId: string;
}

/**
 * Return an always defined `AppInfo` blob, even if, for some reason, Capacitor
 * errors out.
 * For non-capacitor platform, return a generic `AppInfo`.
 * Since we want this to "always work", this function does not reject the promise.
 */
export async function getAppInfo(): Promise<CbhAppInfo> {
  if (isCapacitorPlatform()) {
    try {
      const appInfo = await App.getInfo();
      const currentOtaVersion = await Deploy.getCurrentVersion();
      return {
        ...appInfo,
        otaBuildId: currentOtaVersion?.buildId?.toString() ?? "",
      };
    } catch (error) {
      logError(APP_V2_APP_EVENTS.APP_INFO_ERROR, { error });
    }
  }

  return {
    name: "CBH Mobile App",
    version: "",
    build: "",
    id: "",
    otaBuildId: "",
  };
}

type PartialDeviceInfo = Pick<DeviceInfo, "platform" | "osVersion">;

/**
 * Return an always defined `PartialDeviceInfo` blob, even if, for some reason, Capacitor
 * errors out.
 * For non-capacitor platform, return a generic `PartialDeviceInfo`.
 * Since we want this to "always work", this function does not reject the promise.
 */
export async function getDeviceInfo(): Promise<PartialDeviceInfo> {
  if (isCapacitorPlatform()) {
    try {
      return await Device.getInfo();
    } catch (error) {
      logError(APP_V2_APP_EVENTS.DEVICE_INFO_ERROR, { error });
    }
  }

  return {
    platform: "web",
    osVersion: "web-unknown",
  };
}
