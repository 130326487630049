import { isDefined } from "@clipboard-health/util-ts";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { type SupportLinksVariant } from "@src/appV2/FeatureFlags/types";

import { SupportArticleLinks } from "../constants";
import { type ContactSupportLinks } from "../types";

/**
FIXME: This hook should not be required to fetch support links
We should have a static set of support links within the app, rather than relying on LD flags
*/
export function useSupportLinks(articleName?: keyof SupportLinksVariant): ContactSupportLinks {
  const { [CbhFeatureFlag.SUPPORT_LINKS]: supportLinks = {} } = useCbhFlags() ?? {};

  const howToContactSupportLink =
    supportLinks?.HOW_TO_CONTACT_SUPPORT ?? SupportArticleLinks.HOW_TO_CONTACT_SUPPORT;
  const articleLink = isDefined(articleName) ? supportLinks[articleName] : undefined;
  return {
    howToContactSupportLink,
    articleLink,
  };
}
