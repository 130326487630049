export function formatDistanceInMiles(
  distanceInMiles: number,
  unitDisplay: "long" | "short" = "short"
) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "unit",
    unit: "mile",
    unitDisplay,
    maximumFractionDigits: 0,
  });
  return formatter.format(distanceInMiles);
}
