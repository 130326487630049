import { type LinkProps as RouterLinkProps } from "react-router-dom";

export function isLinkExternal(link: RouterLinkProps["to"]): boolean {
  if (typeof link === "string") {
    return /^(https?:\/\/)/i.test(link);
  }

  if (typeof link === "object") {
    return /^(https?:\/\/)/i.test(link.pathname ?? "");
  }

  return false;
}
