import latinize from "latinize";

export function toFileNameSafeString(name: string): string {
  const withoutAccentsAndDiacritics = latinize(name.trim());

  return (
    withoutAccentsAndDiacritics
      // Replace non-letters with underscores
      .replace(/[^\dA-Za-z]/g, "_")
      // Remove multiple consecutive underscores
      .replace(/_+/g, "_")
      // Remove leading or trailing underscores
      .replace(/^_+|_+$/g, "")
      .toLowerCase()
  );
}
