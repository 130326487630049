import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";

import { type CbhAppInfo, getAppInfo } from "./deviceUtils";

type LocallyDefinedUseQueryOptions = "queryKey" | "queryFn";
type DisallowedUseQueryOptions = "onSuccess" | "onError" | "onSettled";

export type UseAppInfoOptions = Omit<
  UseQueryOptions<CbhAppInfo, void, CbhAppInfo, string[]>,
  LocallyDefinedUseQueryOptions | DisallowedUseQueryOptions
>;

export function useAppInfo(
  options?: UseAppInfoOptions
): UseQueryResult<CbhAppInfo> & { appInfo?: CbhAppInfo } {
  const appInfoResponse = useQuery({
    ...options,
    queryKey: ["native-mobile/get-app-info"],
    queryFn: async () => {
      return await getAppInfo();
    },
  });

  return {
    ...appInfoResponse,
    appInfo: appInfoResponse.data,
  };
}
