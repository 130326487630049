import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";
import QRCode from "qrcode";

export function useQrCodeDataUrl(
  params: { link: string },
  options: UseQueryOptions<string> = {}
): UseQueryResult<string> {
  const { link } = params;
  return useQuery({
    queryKey: [link],
    queryFn: async () => {
      return await QRCode.toDataURL(link);
    },
    ...options,
  });
}
