import { IonAlert, IonButton } from "@ionic/react";
import { GetState } from "@store/store.model";
import { FC, Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

interface LogoutButtonProps {
  logout: () => (dispatch: Dispatch, getState: GetState) => Promise<void>;
  isSignup?: boolean;
  color: string;
  mode: "md" | "ios";
}
/**
 * @deprecated
 */
export const LogoutButton: FC<LogoutButtonProps> = ({
  logout,
  color,
  mode,
  isSignup = false,
}: LogoutButtonProps) => {
  const [logoutConfirmOpen, setLogoutConfirmOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    setLogoutConfirmOpen(false);
  };

  const openLogoutConfirm = () => {
    setLogoutConfirmOpen(true);
  };

  const closeLogoutConfirm = () => {
    setLogoutConfirmOpen(false);
  };

  const message = isSignup
    ? "You're almost done! Are you sure you want to quit signup?"
    : "Are you sure you want to logout?";

  return (
    <Fragment>
      <IonButton color={color} mode={mode} onClick={openLogoutConfirm}>
        {isSignup ? "Quit Signup" : "Logout"}
      </IonButton>
      <IonAlert
        message={message}
        isOpen={logoutConfirmOpen}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: closeLogoutConfirm,
          },
          {
            text: "Ok",
            handler: handleLogout,
          },
        ]}
      />
    </Fragment>
  );
};
