import { ExternalLink } from "@clipboard-health/ui-react";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import {
  SupportArticleLinks,
  SupportArticles,
  type SupportContext,
} from "@src/appV2/support/constants";

export interface HowToContactSupportLinkProps {
  supportContext: SupportContext;
}

export function HowToContactSupportLink(props: HowToContactSupportLinkProps) {
  const { supportContext } = props;

  return (
    <ExternalLink
      to={SupportArticleLinks.HOW_TO_CONTACT_SUPPORT}
      onClick={() => {
        logEvent(APP_V2_USER_EVENTS.OPENED_SUPPORT_ARTICLE, {
          articleLink: SupportArticleLinks.HOW_TO_CONTACT_SUPPORT,
          articleName: SupportArticles.HOW_TO_CONTACT_SUPPORT,
          supportContext,
        });
      }}
    >
      Contact Our 24/7 Support Team
    </ExternalLink>
  );
}
