import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LicenseInfo } from "@mui/x-license-pro";
import { environmentConfig } from "@src/appV2/environment";
import { MuiSnackBarProvider } from "@src/appV2/lib";
import { MuiThemeProvider } from "@src/appV2/lib/Theme";
import type React from "react";

LicenseInfo.setLicenseKey(environmentConfig.REACT_APP_MUI_LICENSE_KEY);

interface MaterialUiProvidersProps {
  children: React.ReactNode;
}
export function MaterialUiProviders(props: MaterialUiProvidersProps) {
  const { children } = props;

  return (
    <MuiThemeProvider>
      <MuiSnackBarProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>
      </MuiSnackBarProvider>
    </MuiThemeProvider>
  );
}
