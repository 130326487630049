/**
 * Returns a US formatted phone number for a given phone number string, ignoring
 * non-digits. Of the digits, ignore anything longer than 10 digits.
 *
 * @param phoneNumber a string representing part of a US phone number, such as `666-666-6666`
 * @returns a US formatted version of that phone number, such as `(666) 666-6666`
 */
export function formatUsPhoneNumber(phoneNumber: string): string {
  const phoneNumberDigits = phoneNumber?.replace(/\D/g, "").slice(0, 10);
  if (!phoneNumberDigits) {
    return "";
  }

  const areaCode = phoneNumberDigits.slice(0, 3);
  const middle = phoneNumberDigits.slice(3, 6);
  const last = phoneNumberDigits.slice(6, 10);

  if (phoneNumberDigits.length > 6) {
    return `(${areaCode}) ${middle}-${last}`;
  }

  if (phoneNumberDigits.length > 3) {
    return `(${areaCode}) ${middle}`;
  }

  return `(${areaCode}`;
}

export enum CountryCode {
  US = "+1",
}

interface FormatPhoneNumberWithCodeParams {
  phoneNumber: string;
  countryCode?: CountryCode;
}

/**
 * @returns a US formatted version of that phone number with, such as `(666) 666-6666`
 */
export function formatPhoneNumberWithCode(params: FormatPhoneNumberWithCodeParams): string {
  const { phoneNumber, countryCode = CountryCode.US } = params;
  const formattedNumber = formatUsPhoneNumber(phoneNumber);

  if (!formattedNumber) {
    return "";
  }

  return `${countryCode} ${formattedNumber}`;
}

export function extractPhoneNumberDigits(phoneNumber: string): string {
  return phoneNumber.replace(/\D/g, "");
}
